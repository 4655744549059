import React, { Component } from 'react'
import { Layout } from 'core/components'
import { Container, Row, Col } from 'reactstrap'
import { queryParams } from 'core/utils'
import { navigate } from 'gatsby'
import { TravelService } from 'modules/travel/services'
import { PayMercadoPagoBody } from 'modules/travel/services/TravelService/types'
/* import { AButton } from 'shared/components/common'*/

interface GraciasParams {
  code: string
  payment_id: string
  preference_id: string
}

class GraciasPorSuCompra extends Component<PageProps, { code: string; payment_id: string; preference_id: string }> {
  state: any = {}
  componentDidMount() {
    const params = queryParams<GraciasParams>(this.props.location)
    if (!params.code || !this.validCode(params.code)) {
      return navigate('/')
    }
    this.setState({ code: params.code })

    if (params.payment_id) {
      this.checkPaymentStatusMercadoPago(params.payment_id)
        .then(payStatusData => {
          if (payStatusData.metadata) {
            const body = Object.assign(payStatusData.metadata, {
              mercadopago_payment_id: params.payment_id,
              mercadopago_preference_id: params.preference_id
            })
            this.finishReservationMercadoPago(body)
              .then(() => {
                this.setState({})
              })
              .catch(err => {
                console.error(err)
              })
          }
        })
        .catch(err => {
          console.error(err)
        })
    }
  }

  validCode(code: string) {
    const regex = /B[0-9]{4}[A-Z0-9]{6}/
    return regex.test(code)
  }

  checkPaymentStatusMercadoPago(paymentId: string) {
    return TravelService.checkPaymentStatusMercadoPago(paymentId)
      .then(data => {
        return Promise.resolve(data)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }

  finishReservationMercadoPago(finishReservationBody: PayMercadoPagoBody) {
    return TravelService.payTravelMercadoPago(finishReservationBody)
      .then(data => {
        return Promise.resolve(data)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }

  render() {
    const {
      pageContext: { locale }
    } = this.props
    const { code } = this.state
    return (
      <Layout locale={locale}>
        <Container className="pad">
          <Row>
            <Col sm={{ size: 6, offset: 3 }} xs={12} className="text-center">
              <br />
              <br />
              <br />
              <h4 className="big-blue">¡Gracias por tu compra!</h4>
              <h5 className="big-gray">Tu número de reservación:</h5>
              <h2 className="big-pink-op">{code}</h2>
              <br />
              {/* <AButton variant="big">Ver detalles de compra</AButton> */}
              <br />
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default GraciasPorSuCompra
